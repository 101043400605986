<template>
	<div>
		<div class="d-flex mb-2 justify-content-between container-uls-inventories">
			<ul class="card w-100 flex-row nav nav-tabs align-items-center gap-x-2 mb-0 p-2" role="tabslits">
				<b-nav-item
					v-for="(tab, index) in tabArray"
					link-classes="px-1 justify-content-between"
					class="x-tab-width"
					href="#"
					:key="`${index}-index`"
					:active="tabIndex == index"
					@click="selectTabInventory(index, tab.key)"
				>
					<span>{{ tab.name }}</span>
					<!-- <b-badge pill variant="danger" class="ml-1">
						{{ tab.counter }}
					</b-badge> -->
				</b-nav-item>
			</ul>

			<div
				class="container-btn mt-2 mt-md-0 card mb-0 ml-md-2 flex-row p-1 d-flex align-items-center justify-content-between"
			>
			<!-- {{ $route.name }} -->
				<template v-if="$route.name == 'inventory-specimens-list-sr'">
					<button
						class="btn btn-primary d-flex align-items-center justify-content-center"
						@click="
							modalCreateInventoryParent.show = true;
							modalCreateInventoryParent.title = `Nuevo inventario`;
						"
					>
						<FeatherIcon icon="PlusIcon" class="" size="18" /> Nuevo
					</button>
				</template>
				<template v-else>
					<!-- ASD -->
					<!-- {{ inventory.code }} -->
					<div v-if="inventory.code || inventory.alias" class="d-flex flex-column align-items-center w-100 justify-content-center">
						<h5 class="mb-0 font-weight-bolder" v-b-tooltip.hover title="Codigo">
							{{ inventory.code || inventory.alias }}
						</h5>
						<p class="mb-0">{{ inventory.dad_code }}</p>
					</div>
					<button
						v-else
						class="btn btn-primary d-flex align-items-center justify-content-center"
						@click="createInventoryMain"
					>
						<FeatherIcon icon="PlusIcon" class="" size="18" /> Nuevo
					</button>
				</template>
			</div>
		</div>

		<b-sidebar
			v-model="sidebarCrearInventario.show"
			:title="sidebarCrearInventario.title"
			left
			width="90%"
			sidebar-class="sidebar-class"
			body-class="p-0 bg-white"
			scrollable
		>
			<CreateInventorySpecimens
				@refresh="refresh"
				v-if="sidebarCrearInventario.show"
				:data="dataSpecimens"
				:module="module"
			/>
		</b-sidebar>

		<b-modal
			v-model="modalCreateInventoryParent.show"
			:title="modalCreateInventoryParent.title"
			body-class="p-0 m-0"
			hide-footer
		>
			<b-form-group label="Fecha de inicio" class="m-1">
				<b-form-datepicker
					placeholder="Ingresar fecha"
					v-model="start_date"
					:date-format-options="{}"
					locale="es"
				></b-form-datepicker>
			</b-form-group>

			<div class="d-flex justify-content-end p-1 container-footer">
				<button
					@click="createInventory"
					class="btn btn-primary d-flex align-items-center justify-content-center"
				>
					<FeatherIcon icon="SaveIcon" size="18" class="icon text-white cursor-pointer mr-25" />
					Guardar
				</button>
			</div>
		</b-modal>

		<router-view></router-view>
	</div>
</template>

<script>
import moment from "moment";
import { mapState, mapActions, mapMutations } from "vuex";
import CreateInventorySpecimens from "./specimens/components/CreateInventorySpecimens.vue";

export default {
	name: "InventoryMain",
	components: {
		CreateInventorySpecimens,
	},
	data() {
		return {
			tabArray: [
				{
					name: "Ejemplares",
					key: "specimens",
				},
				// {
				// 	name: "Pollos",
				// 	key: "chicks",
				// },
			],
			tabIndex: this.$route.name.includes("specimens") ? 0 : 1,
			sidebarCrearInventario: {
				show: false,
				title: null,
				data: [],
			},
			dataSpecimens: [],
			modalCreateInventoryParent: {
				show: false,
				title: null,
			},
			start_date: new Date(),
		};
	},
	computed: {
		...mapState("inventorySpecimens", ["inventories", "inventory"]),
		module() {
			return this.$route.meta.module;
		},
	},
	mounted() {
		// this.SELECT_INVENTORY({
		// 		item: {
		// 			value: [],
		// 		},
		// 	});
	},
	methods: {
		...mapActions("inventorySpecimens", ["getInventories", "storeInventory"]),
		...mapMutations("inventorySpecimens", ["SELECT_INVENTORY", "SET_FILTERS"]),
		selectTabInventory(index, key) {
			this.tabIndex = index;
			this.$router.push({
				name: `inventory-${key}-list-sr`,
			});
			this.SET_FILTERS({
				search: null,
				page: 1,
			});
			this.SELECT_INVENTORY({
				item: {
					value: [],
				},
			});
		},
		async init() {
			this.isPreloading();
			await this.getInventories({ module: this.module });
			this.isPreloading(false);
		},
		createInventoryMain() {
			this.sidebarCrearInventario.show = true;
			this.sidebarCrearInventario.title = `Nuevo inventario - ${this.module == 1 ? "Ejemplares" : "Pollos"}`;
			this.SELECT_INVENTORY({
				item: {
					value: [],
				},
			});
		},
		async refresh() {
			this.sidebarCrearInventario.show = false;
			await this.init();
		},
		async createInventory() {
			const { isConfirmed } = await this.showConfirmSwal();
			if (!isConfirmed) return;
			this.isPreloading();
			await this.storeInventory({
				module: 1,
				value: [],
				start_date: moment(this.start_date).format("YYYY-MM-DD"),
				parent_id: null,
				alias: null,
				comment: null,
			});

			this.modalCreateInventoryParent.show = false;

			await this.refresh();
			this.isPreloading(false);
		},
	},
	watch: {
		module() {
			this.tabIndex = this.$route.name.includes("specimens") ? 0 : 1;
		},
	},
};
</script>

<style scoped lang="scss">
.container-uls-inventories {
	display: flex;
	flex-direction: column;
	@media screen and (min-width: 700px) {
		flex-direction: row;
	}
	.container-btn {
		width: 100%;
		@media screen and (min-width: 700px) {
			width: 180px;
		}
		button {
			width: 100%;
			@media screen and (min-width: 700px) {
				width: 100%;
				// margin-right: .6rem;
			}
		}
	}
}
.nav-link {
	// background: red;
	transition: 0.3s all ease-in-out;
}
.active {
	// background: #7367f0 !important;
	border-bottom: 3px solid #7367f0 !important;
	// border-radius: 8px;
	// box-shadow: 0 1px 3px 0 rgb(0 0 0 / 0.1), 0 1px 2px -1px rgb(0 0 0 / 0.1) !important;
	span {
		color: #7367f0 !important;
		// background: green ;
		// z-index: 100;
	}
}
.nav-tabs .nav-link.active:after {
	background: none !important;
	display: none;
}
.container-footer {
	background: #f8f8f8;
	.btn {
		// padding: .4rem .8rem !important;
		// font-size: 13px !important;
	}
}
</style>
